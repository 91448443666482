.imprimirComponent {
  position: fixed;
  top: 120px;
  right: 0px;
  background-color: white;
  color: black;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  padding: 10px;
  z-index: 80000;
}
