.fondoPrincipal {
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  min-height: 80vh;

  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5)),
    url("https://www.funerariavitanova.com/background/1.png") no-repeat 50% fixed;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  width: 700px;
  text-align: center;
}

